import { template as template_8aee98b9c9864d79bcfeca4c971cecee } from "@ember/template-compiler";
const FKText = template_8aee98b9c9864d79bcfeca4c971cecee(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
