import { template as template_c39395a1c1964f4ea96722671107bb16 } from "@ember/template-compiler";
const FKControlMenuContainer = template_c39395a1c1964f4ea96722671107bb16(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
