import { template as template_18440b68f0894ce8a8cbe868f2d757aa } from "@ember/template-compiler";
const FKLabel = template_18440b68f0894ce8a8cbe868f2d757aa(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
